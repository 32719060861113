<template>
  <div>
    <general-table
      ref="CouponTable"
      :api-url="apiUrl"
      :add-type="addType"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="false"
      :block-content="false"
      :edit-content="true"
      :view-content="false"
      :columns="columns"
      :type="type"
      :selectable="false"
    >
      <template #filter>
        <b-col
          cols="12"
          md="3"
        >
          <b-input-group>
            <b-form-select
              v-model="filter.is_active"
              value-field="value"
              text-field="text"
              :options="status"
            >
              <template #first>
                <b-form-select-option
                  disabled
                >
                  Status
                </b-form-select-option>
              </template>
            </b-form-select>

            <b-input-group-append v-if="filter.is_active">
              <b-button
                size="sm"
                @click="filter.is_active = ''"
              >
                <span class="lead">
                  &times;
                </span>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>

        <b-button
          variant="primary"
          :disabled="Object.values(filter).length < 1"
          @click="$refs.CouponTable.getAllData()"
        >
          Filter
        </b-button>

      </template>
      <template
        #cell(status)="data"
      >
        <b-badge
          class="change-status"
          :variant="changeVaraint(getTypes(data.item.is_active))"
        >
          {{ getTypes(data.item.is_active) }}
        </b-badge>
      </template>
      <template
        #cell(type)="data"
      >
        {{ getOfferTypes(data.item.type) }}

      </template>
      <template
        #cell(offer)="data"
      >
        {{ data.item.offer }}<span v-if="data.item.type===1">%</span>

      </template>
    </general-table>
    <div>
      <b-modal
        ref="view-modal"
        hide-footer
        centered
      >
        <view-coupon />
      </b-modal>
    </div>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'admin/coupons',
      urlType: 'coupons',
      addType: 'Add PromoCode',
      addComponentName: 'add-promoCode',
      editComponent: 'edit-promoCode',
      type: 'page',
      columns: [
        { key: 'code', label: 'Coupon Code' },
        { key: 'description', label: 'Description' },
        { key: 'status', label: 'Status' },
        { key: 'type', label: 'Offer Type' },
        { key: 'offer', label: 'Offer' },
        { key: 'min_order', label: 'Min Order' },
        { key: 'max_discount', label: 'Max Discount' },
        { key: 'original_expire_at', label: 'Expired at' },
        { key: 'actions' },
      ],
      product_gifts: [],
      filter: {},
      status: [
        { text: 'Active', value: '1' },
        { text: 'Not Active', value: '0' },
      ],
      types: '',
    }
  },
  computed: {

    apiUrl() {
      let base = 'admin/coupons?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }

      return base.slice(0, -1)
    },
  },

  methods: {
    changeVaraint(type) {
      // eslint-disable-next-line eqeqeq
      if (type == 'Active') return 'success'

      return 'danger'
    },
    filterTypes(type) {
      const filteredType = this.status.filter(item => item.text !== type)
      this.types = filteredType
    },
    getTypes(type) {
      if (type === 1) {
        return 'Active'
      } if (type === 0) {
        return 'Not Active'
      }
      return 'Active'
    },
    getOfferTypes(type) {
      if (type === 1) {
        return 'Percentage'
      } if (type === 0) {
        return 'Amount'
      }
      return 'Amount'
    },
  },
}
</script>

<style>

</style>
